import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/shahid/Desktop/Projects/Personal/madrasatulilm.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Box, Breadcrumb, Divider, SEO, Text, BookList, Button, Video } from '../../../views/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO pageTitle="ছবি দেখে আরবী শব্দ শেখা" mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
      <Text variant="h2" textAlign="center" mdxType="Text">
  ছবি দেখে আরবী শব্দ শেখা
      </Text>
      <Divider mdxType="Divider" />
      <Breadcrumb links={[{
        url: '/',
        name: 'নীড়পাতা'
      }, {
        url: '/study-materials/',
        name: 'শিক্ষাপোকরণ'
      }, {
        url: '/study-materials/arabic',
        name: 'আরবী'
      }]} mdxType="Breadcrumb" />
      <Divider mdxType="Divider" />
      <p>{`ছোটবেলায় আমরা মানব দেহ, ফল-ফুল, পশু-পাখি কত কিছুর নাম বাংলা আর ইংরেজীতে এক সাথে মুখস্থ করেছি। এই দুই ভাষার সাথে শুধু আরবীটা জুড়ে দিলে কিন্তু আমাদের কোনো ক্ষতি হত না, বরং বাড়ত ৩ ভাষার উপই দক্ষতা।`}</p>
      <p>{`আর তাই মাদ্রাসাতুল ইল্‌ম প্রেসেন্টেশন আকারে (ছবি সহ) তৈরি করেছে ৩ ভাষার শব্দ ভান্ডার। ৩ ভাষা বললেও বেশিরভাগ প্রেসেন্টেশনেই বাংলা শব্দ ব্যবহার করা হয় নি। কারণ, এটা ছবি দেখেই বোঝা যায়।`}</p>
      <p>{`বাংলা ব্যবহার না করার আরেকটা কারণ, অনেকেই বাচ্চাকে এভাবে পড়ান, " قِطٌّমানে বিড়াল", যা আসলে ভাষা শেখার সঠিক পদ্ধতি নয়। বরং, একটা বিড়ালের ছবি দেখিয়ে বলা যেতে পারে, "هٰذَا قِطٌّ"।`}</p>
      <p>{`এভাবে প্রশ্নও করা যেতে পারে, "مَا هَذَا؟"। তাহলেই বাচ্চারা প্রয়োজনীয় শব্দগুলো আরবী ভাষাতেই আলাদা আলাদা ভাবে শিখবে, ইন শা আল্লাহ।`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1bVOurJIRBmKorW0jKQe4Roon1j5ToOFt/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true"
          }}>{`আরবী হরফ দিয়ে শব্দের প্রেসেন্টেশন (আস সীরাত বইয়ের আলোকে) ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1tymflpdl9L8i0lH0f6BDmc7AwDfhUamT/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true"
          }}>{`পরিবার-আত্মীয়তা বিষয়ক শব্দের প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1MtJirwhf47gWR1Q99HjzBPLtG6Dx2PwD/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true"
          }}>{`বাসাবাড়ি বিষয়ক শব্দের প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1aVmuAim5-M13Esa7xNDDsJkRASo2OOGV/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true"
          }}>{`বাথরুম বিষয়ক শব্দের প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1Z_EL7Rv6eYE-YPTDxZzu35bQGmWZaNsc/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true"
          }}>{`রান্নাঘর বিষয়ক শব্দের প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1v26fFgChNagUhLCJ5WiQ1F-Cl7bZkH2a/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true"
          }}>{`মানবদেহ বিষয়ক শব্দের প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1lSXNJyh2_6nX41te-xAldcRud1Oa3afD/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true"
          }}>{`খাদ্য বিষয়ক শব্দের প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/16WYyQnrw9LQoUWXoa1YOthN2KSN8gxHB/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true"
          }}>{`ফল বিষয়ক শব্দের প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1sZ04fgn-U8ANk7Gze8CdcCi8mdUoTt9P/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true"
          }}>{`শাকসবজি বিষয়ক শব্দের প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1pblm2w3Cq9yK_Cmeo3Ye9AUcUjXCxoFW/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true"
          }}>{`প্রাণী বিষয়ক শব্দের প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1nA7gLLP_nb_ZClkea2ttR5vOhAjP4g8U/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true"
          }}>{`পাখি বিষয়ক শব্দের প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1wxWCPyrVYVfAGIeyEflBkfq7AbiQfU8G/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true"
          }}>{`মাছ, পোকা ও অন্যান্য বিষয়ক শব্দের প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1En5bqqVbenV5rmWYm3EF5-LBSdY8hK8K/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true"
          }}>{`পোশাক ও সাজসজ্জা বিষয়ক শব্দের প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1JHcRFtC-E-8rPnwOhlG28jrH7gpw3geP/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true"
          }}>{`রঙের নাম বিষয়ক শব্দের প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1uFTIM5A5lhceZYLgL2EaflWzSGXvJQ6c/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true"
          }}>{`যানবাহন বিষয়ক শব্দের প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1t0fw8VcSeMOShh1nLHxusz7aeSE7_5DE/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true"
          }}>{`রাস্তাঘাট বিষয়ক শব্দের প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1xQ6SI0L_mwTzgz-_sAUc6ciwREH_tFek/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true"
          }}>{`শিক্ষা বিষয়ক শব্দের প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1VqlY2mJwl1yxPiDu-80vqkNKxiwZ7tpr/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true"
          }}>{`ধর্ম বিষয়ক শব্দের প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1HlEuMNjwfugRAegKnp8-PYxaLKi-KZZX/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true"
          }}>{`চাকরি ও ব্যবসা-বাণিজ্য বিষয়ক শব্দের প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1POGD259_lkdxt6rmGzNlAUB3NAsGyPdB/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true"
          }}>{`প্রকৃতি বিষয়ক শব্দের প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1FW20BQsNNoCtmZFk87ZxU7XJS2Bbrrd4/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true"
          }}>{`ভূগোল বিষয়ক শব্দের প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/181009BiTEVG9xLlGfxNZ6HjYn1uR6SVN/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true"
          }}>{`খেলাধূলা বিষয়ক শব্দের প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/18T4mCg_NM4oAQPycw_tIgJdtGaL2a_as/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true"
          }}>{`স্বাস্থ ও চিকিৎসা বিষয়ক শব্দের প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1DBWsMKR8XDzH6fnn4DZYcRl6ddLW_529/edit?usp=drivesdk&ouid=106179897632712562234&rtpof=true&sd=true"
          }}>{`প্রযুক্তি বিষয়ক শব্দ (কম্পিউটারের যন্ত্রাংশ) এর প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1UDbViMMlyorU-BjerDi4zrtMin88x1rz/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true"
          }}>{`ব্যক্তিত্ব ও চরিত্রসূচক বিষয়ক শব্দের প্রেসেন্টেশন ডাউনলোড`}</a></li>
      </ul>
      <Video videoSrcURL="https://www.youtube.com/embed/videoseries?list=PLN6GIGge5un9gKYMXvM0YtQrlO5_TlxGA" videoTitle="Arabic Words with English Meaning and Pictures" frameWidth="720px" frameHeight="504px" width="100%" mt={4} mdxType="Video" />
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      